import React, { Fragment } from "react"
import {
  Breadcrumb,
  Container,
  Row,
  Col,
  Form,
  Button,
  Table,
} from "react-bootstrap"
import bannerImg from "../assets/images/lubraicant-fundamentals-banner.jpg"
import baseOilImg from "../assets/images/base-oil.jpg"
import LayoutAnimate from "../components/animation/LayoutAnimate"
import { Parallax } from "react-parallax"
const ApiClassification = () => {
  return (
    <LayoutAnimate>
            <section className="page-banner-section">
        <Parallax
        bgImage={bannerImg}
        bgImageAlt="Call to action"
        strength={200}
        className="cta-section"
        renderLayer={percentage => (
          <div
              style={{
                  position: 'absolute',
                  background: `linear-gradient(to right, rgba(2, 23, 32,0.9),rgba(2, 23, 32,0.2))`,
                  left: '0',
                  top: '0',
                  width: '100%',
                  height: '100%',
              }}
          />
      )}
      >
       <div className="page-banner-wrapper">
       <Container className="page-banner-title-container">
          <h2>Api Classification</h2>
        </Container>
        <div className="breadcrumnb-wrapper">
          <Container className="breadcrumb-container pb-3">
            <Breadcrumb>
              <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
              <Breadcrumb.Item active>Api Classification</Breadcrumb.Item>
            </Breadcrumb>
          </Container>
        </div>
       </div>
      </Parallax>
      </section>
      <section className="contact-section layout-padding">
        <Container>
          <h2 className="mb-5">API Classification For Engine Lubricant</h2>

          <h4 className="mb-3 text-secondary">
            API Classified Engine Lubricant Grades by Fuel
          </h4>
          <div className="table-wrapper">
            <Table responsive="md" className="grade-fuel-table">
              <thead>
                <tr>
                  <th>“S” Service Oils for Gasoline (PETROL)</th>
                  <th>“C” Service Oils for Diesel</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>SA (Obsolete) </td>
                  <td>CA (Obsolete) </td>
                </tr>
                <tr>
                  <td>SB (Obsolete) </td>
                  <td>CB (Obsolete)</td>
                </tr>
                <tr>
                  <td>SC (1964)</td>
                  <td>CC (Obsolete) </td>
                </tr>
                <tr>
                  <td>SD (1968)</td>
                  <td>CD (Obsolete)</td>
                </tr>
                <tr>
                  <td>SE (1972)</td>
                  <td>CE (1983-Obsolete) </td>
                </tr>
                <tr>
                  <td>SF (1980)</td>
                  <td>CF (1985)</td>
                </tr>
                <tr>
                  <td>SG (1989)</td>
                  <td>CF-4 (1990)</td>
                </tr>
                <tr>
                  <td>SH (1994)</td>
                  <td>CG-4 (1994)</td>
                </tr>
                <tr>
                  <td>SJ (1997)</td>
                  <td>CH-4 (1998)</td>
                </tr>
                <tr>
                  <td>SL (2001)</td>
                  <td>CI-4 (2002)</td>
                </tr>
                <tr>
                  <td>SM (2004)</td>
                  <td>CI-4 PLUS (2004)</td>
                </tr>
                <tr>
                  <td>SN (2011)</td>
                  <td>CJ-4 (2007)</td>
                </tr>
              </tbody>
            </Table>
          </div>
          <div className="layout-padding-top">
            <h2>API Classification For Base Oil</h2>
            <img
              src={baseOilImg}
              alt="Base oil image"
              className="img-fluid mt-2 mb-3"
            />
            <p className="mb-0">
              A quality of Lubricant Depend on Base Oil quality and dosage and
              quality of Additives. API has established base oil classification
              based on Physical and Chemical characteristics of Base stock.
            </p>
          </div>
          <div className="layout-padding2-top layout-padding-bottom">
            <h4 className="text-secondary">Base Oil Categories</h4>
            <div className="table-wrapper">
              <Table responsive="md" className="base-oil-table">
                <thead>
                  <tr>
                    <th>Category</th>
                    <th>% Saturate</th>
                    <th>% Sulfur</th>
                    <th>VI</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Group 1</td>
                    <td>&lt;90</td>
                    <td>&gt;0.03</td>
                    <td>80 and &lt;120</td>
                  </tr>
                  <tr>
                    <td>Group 2</td>
                    <td>&gt;90</td>
                    <td>&lt;0.03</td>
                    <td>80 and &lt;120</td>
                  </tr>
                  <tr>
                    <td>Group 3</td>
                    <td>&gt;90</td>
                    <td>&lt;0.03</td>
                    <td>&gt;120</td>
                  </tr>
                  <tr>
                    <td>Group 4</td>
                    <td colspan="3" rowspan="2">
                      PolyAlfaOlefins (PAO) <br />
                      All Other Base stocks Not included in Group 1 to Group 4
                    </td>
                  </tr>
                  <tr>
                    <td>Group 5</td>
                  </tr>
                </tbody>
              </Table>
            </div>
          </div>
          <h4 className="text-secondary">Percentage Of Saturates :</h4>
          <p className="mb-4">
            Unsaturated hydrocarbons found in crude must be removed or converted
            in to more stable, Saturated molecules to Improve Oxidative
            Stability and Decrease sludge formation.
          </p>

          <h4 className="text-secondary">Level Of Sulfur :</h4>
          <p className="mb-4">
            Sulfur is considered an impurity that is removed in refining. It may
            be undesirable a it can affect performance and It may affect
            vehicular emission when burned.
          </p>

          <h4 className="text-secondary">Viscosity Index :</h4>
          <p className="mb-0">
            This measurement indication the relative change in viscosity with
            temperature change. Higher viscosity index are preferable. The
            higher number the less an oil thins at high temperatures and
            thickens at low temperatures.
          </p>
        </Container>
      </section>
    </LayoutAnimate>
  )
}

export default ApiClassification
